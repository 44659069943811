$(window).on('load', function() {

  if ( !$('.gform_wrapper .custom-contact-form').length ) return;

  // Add containers for column layout
  var $fields_list = $('.gform_body > ul.gform_fields');
  $fields_list.append('<li class="uk-width-1-3 gform-col" id="gform-block-1"><ul></ul></li>');
  $fields_list.append('<li class="uk-width-2-3 gform-col" id="gform-block-2"><ul class="gform-grid"></ul></li>');
  $fields_list.append('<li class="uk-width-1-3 gform-col uk-grid-margin" id="gform-block-3"><ul class="gform-grid"></ul></li>');
  $fields_list.append('<li class="uk-width-2-3 gform-col uk-grid-margin" id="gform-block-4"><ul class="gform-grid uk-flex uk-flex-between"></ul></li>');

  var custom_gfields = {
    $firstrow: $('.custom-contact-form .gfield.firstrow'),
    $secondrow: $('.custom-contact-form .gfield.secondrow')
  }

  // Distribute fields among columns according to classes given in GF
  custom_gfields.$firstrow.each( function( index, element ) {
    if ( custom_gfields.$firstrow.eq(index).hasClass('firstcolumn') ) {
      $('#gform-block-1 > ul').append(element);
    } else {
      $('#gform-block-2 > ul').append(element);
    }
  });
  custom_gfields.$secondrow.each( function( index, element ) {
    if ( custom_gfields.$secondrow.eq(index).hasClass('firstcolumn') ) {
      $('#gform-block-3 > ul').append(element);
    } else {
      $('#gform-block-4 > ul').append(element);
    }
  });
  $('#gform-block-4 > ul').append($('.gform_button[type="submit"]')[0]);


  // Checkboxes
  $('.gfield_checkbox').find('input[type="checkbox"]').addClass('checkbox');
  $('.gfield_checkbox').find('input[type="checkbox"]').after('<span class="check"></span>');
  $('.js-required-first').find('input[type="checkbox"]').first().prop('required', true);


  /**
   * Gravity Forms uses Plupload (http://www.plupload.com/) as a frontend file uploader.
   * It is necessary to bind the uploader object from GF to the FileUploaded event to deal with the
   * HTML markup that shows up when the file upload is complete.
   */

  $('.ginput_container_fileupload .ginput_preview').addClass('-finished');
  setTimeout(function() {
    var file_uploader_id = $('.custom-contact-form .ginput_container_fileupload > [id^="gform_multifile_upload_"]').attr('id');
    gfMultiFileUploader.uploaders[file_uploader_id].bind('FileUploaded', function( uploader, file, result ) {
      if( 200 === result.status ) {
        $('#' + file.id).addClass('-finished');
      }
    });
  }, 150);

  // Martelar string de botão de submissão de ficheiros
  var $input_fields = $('.gform_button_select_files');
  var input_fields_new_string = wp_vars.strings.gform_fileupload_string;
  $input_fields.val(input_fields_new_string);

  // Add icons to buttons

  // File Buttons
  var $drop_area;
  var $drop_areas = $('.gform_drop_area');
  $drop_areas.each(function(index, element) {
    $drop_area = $drop_areas.eq(index);
    $drop_area.addClass('button -regular -icon-next');
    $drop_area.append('<span>' + $drop_area.children('.gform_button_select_files').attr('value') + '</span>');
  });

  // Submit Buttons
  var $button;
  var $buttons = $('.gform_button');
  $buttons.each(function(index, element) {
    $button = $buttons.eq(index);
    $button.parent().append('<li class="uk-flex uk-flex-center"><div class="uk-inline"><div id="' + $button.attr('id') + '-container" class="button -regular -icon-submit"></div></div></li>')
    button_container = $('#' + $button.attr('id') + '-container');
    button_container.append('<span>' + $button.attr('value') + '</span>');
    button_container.append(element);
  });
});
